import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Checkbox } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';

const SpecificCategoryWidget = ({ value, onChange, id, ...rest }) => {
  const [updatedValue, setUpdatedValue] = useState(
    value ? JSON.parse(value) : [],
  );
  const onChangeTerm = (index, field, value) => {
    let newValues = [...updatedValue];
    newValues[index][field] = value;

    setUpdatedValue(newValues);

    onChange(id, JSON.stringify(newValues));
  };

  const headers = Object.keys(updatedValue?.[0] ? updatedValue[0] : {});

  return (
    <FormFieldWrapper id={id} {...rest} className={'specificCategoryField'}>
      <Table>
        <Table.Header>
          <Table.Row>
            {headers?.map((h) => (
              <Table.HeaderCell className={`specific-category-${h}`}>
                {h === 'label' ? null : h}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* Render the remaining columns from the default value object */}
          {updatedValue?.map((row, i) => {
            const rowId = row.label;
            return (
              <Table.Row>
                {Object.keys(row).map((column) => {
                  if (column === 'label') {
                    return (
                      <Table.Cell
                        key={`cell-${column + rowId}`}
                        className={`specific-category-${column
                          .replace(' ', '-')
                          .toLowerCase()}`}
                      >
                        {rowId}
                      </Table.Cell>
                    );
                  } else if (column === 'value')
                    return (
                      <Table.Cell
                        key={`cell-${column + rowId}`}
                        className={`specific-category-${column
                          .replace(' ', '-')
                          .toLowerCase()}`}
                      >
                        <Input
                          id={column + rowId}
                          value={row[column]}
                          required={false}
                          onChange={(e, { value }) =>
                            onChangeTerm(i, column, value)
                          }
                          key={column + rowId}
                          placeholder={'Importo'}
                        />
                      </Table.Cell>
                    );
                  return (
                    <Table.Cell
                      key={column}
                      className={`specific-category-${column
                        .replace(' ', '-')
                        .toLowerCase()}`}
                    >
                      <Checkbox
                        id={column + rowId}
                        checked={row[column]}
                        required={false}
                        onChange={(e, { checked }) =>
                          onChangeTerm(i, column, checked)
                        }
                        key={column + rowId}
                      />
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </FormFieldWrapper>
  );
};

SpecificCategoryWidget.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SpecificCategoryWidget;
