import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { getFileExtension } from '@package/helpers';

const messages = defineMessages({
  folder_allegati: {
    id: 'folder_allegati',
    defaultMessage: 'Raccolta di tutti gli allegati caricati.',
  },
  folder_persone: {
    id: 'folder_persone',
    defaultMessage: 'Raccolta di tutte le anagrafiche caricate.',
  },
});

const FolderContentsTable = ({ items, content }) => {
  const intl = useIntl();
  return (
    <div className="folderContentsContainer">
      {(content['@type'] === 'CartellaFile' ||
        content['@type'] === 'CartellaReferenza') && (
        <p>{intl.formatMessage(messages.folder_allegati)}</p>
      )}
      {content['@type'] === 'CartellaPersone' && (
        <p>{intl.formatMessage(messages.folder_persone)}</p>
      )}
      <div className="folderContentsTable">
        {items?.map((i) => {
          const icon =
            i['@type'] === 'AlboFile'
              ? getFileExtension(i?.albo_file_mimetype)
              : i['@type'] === 'Referenza'
              ? ''
              : 'user-alt';
          return (
            <div key={i.id} className="tableRow">
              <UniversalLink href={flattenToAppURL(i.url)}>
                {i.title}
              </UniversalLink>
              <FontAwesomeIcon icon={icon} title="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FolderContentsTable;

FolderContentsTable.propTypes = {
  content: PropTypes.object,
};
