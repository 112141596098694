/*
CUSTOMIZATIONS:
- add custom button and actions to right side
- change class logics and right side presence logics
- removed description in header
*/

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import Image from '@plone/volto/components/theme/Image/Image';
import {
  Sharing,
  Actions,
  PageHeaderDates,
  PageHeaderEventDates,
  PageHeaderPersona,
  PageHeaderBando,
  PageHeaderNewsItem,
  PageHeaderTassonomiaArgomenti,
  PageHeaderExtend,
  ArgumentIcon,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  AddEditLink,
  DuplicaReferenza,
  ConfermaAnagrafica,
} from '@package/components/views/Commons';

/**
 * PageHeader view component class.
 * @function PageHeader
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */

const messages = defineMessages({
  reading_time: {
    id: 'reading_time',
    defaultMessage: 'Tempo di lettura',
  },
  minutes: {
    id: 'minutes',
    defaultMessage: 'min',
  },
});

const PageHeader = (props) => {
  const intl = useIntl();
  const show_actions =
    typeof props?.show_actions !== 'undefined' ? props?.show_actions : true;
  return (
    <div className="PageHeaderWrapper mb-4">
      <div className="row mb-2 mb-lg-0 page-header">
        <div
          className={cx('py-lg-2 page-header-left', {
            'col-lg-6': props.imageinheader,
            'col-lg-7':
              !props.imageinheader &&
              (props.addLink ||
                props.editLink ||
                props.deleteLink ||
                props.content?.confirm_anagrafica?.show_button ||
                props.content?.confirm_anagrafica?.action),
            'col-lg-12':
              !props.imageinheader &&
              !(
                props.addLink ||
                props.editLink ||
                props.deleteLink ||
                props.content?.confirm_anagrafica?.show_button ||
                props.content?.confirm_anagrafica?.action
              ),
          })}
        >
          {(props.content.icon || props.content.icona) && (
            <ArgumentIcon icon={props.content.icon || props.content.icona} />
          )}
          <h1 data-element="service-title" style={{ wordWrap: 'break-word' }}>
            {props.content.title}
            {props.content.subtitle && ` - ${props.content.subtitle}`}
            {props.content.sottotitolo && ` - ${props.content.sottotitolo}`}
          </h1>

          <PageHeaderEventDates content={props.content} />

          <PageHeaderBando content={props.content} />

          <PageHeaderPersona content={props.content} />

          <PageHeaderNewsItem content={props.content} />

          <PageHeaderExtend {...props} />

          {(props.showreadingtime || props.showdates) && (
            <div className="row mt-5 mb-4 readingtime-dates">
              {props.showdates ? (
                <PageHeaderDates content={props.content} />
              ) : (
                <div className="col-6"></div>
              )}

              {props.showreadingtime &&
                props.readingtime > 0 &&
                ((
                  <div className="col-6">
                    <small>{intl.formatMessage(messages.reading_time)}</small>
                    <p className="font-weight-semibold">
                      {props.readingtime} {intl.formatMessage(messages.minutes)}
                    </p>
                  </div>
                ) || <div className="col-6" />)}
            </div>
          )}
        </div>

        {props.imageinheader && props.content[props.imageinheader_field] ? (
          <div className="col-lg-2 page-header-image">
            <figure>
              <Image
                image={props.content[props.imageinheader_field]}
                alt={props.content.title}
                className="img-fluid"
                maxSize={300}
              />
            </figure>
          </div>
        ) : null}

        {(props.addLink ||
          props.editLink ||
          show_actions ||
          props.showtassonomiaargomenti ||
          props.content?.confirm_anagrafica?.show_button ||
          props.content?.confirm_anagrafica?.action) && (
          <div
            className={cx('page-header-right py-lg-4 ', {
              'col-lg-5 d-flex align-items-start flex-nowrap justify-content-end': true,
            })}
          >
            {show_actions && (
              <div className="row">
                <Sharing
                  url={props.content['@id']}
                  title={props.content.title}
                />
                <Actions
                  url={props.content['@id']}
                  title={props.content.title}
                />
              </div>
            )}
            {(props.addLink ||
              props.editLink ||
              props.deleteLink ||
              props.content) && (
              <div className="albo-actions">
                {props.addLink && (
                  <div className="d-flex align-items-center justify-content-end mt-2">
                    <AddEditLink mode={'add'} link={props.addLink} />
                  </div>
                )}
                {props.editLink && (
                  <div className="d-flex align-items-center justify-content-end mt-2">
                    <AddEditLink mode={'edit'} link={props.editLink} />
                  </div>
                )}
                {props.deleteLink && (
                  <div className="d-flex align-items-center justify-content-end mt-2">
                    <AddEditLink mode={'delete'} link={props.deleteLink} />
                  </div>
                )}
                <DuplicaReferenza content={props.content} />
                <ConfermaAnagrafica content={props.content} />
              </div>
            )}

            {props.showtassonomiaargomenti && (
              <PageHeaderTassonomiaArgomenti content={props.content} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
    readingtime: PropTypes.string,
    showreadingtime: PropTypes.bool,
    imageinheader: PropTypes.bool,
    imageinheader_field: PropTypes.string,
    showdates: PropTypes.bool,
    showtassonomiaargomenti: PropTypes.bool,
    showtactions: PropTypes.bool,
    addlink: PropTypes.string,
  }),
};
