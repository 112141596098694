// /**
//  * Faq reducer.
//  * @module reducers/faq/faq
//  */

import {
  CONFIRM_ANAGRAFICA,
  DUPLICATE_REFERENCE,
  RESET_STATE,
} from '../../constants/ActionTypes';

const duplicateInitialState = {
  error: null,
  result: null,
  loaded: false,
  loading: false,
};
const confirmInitialState = {
  error: null,
  success: false,
  loaded: false,
  loading: false,
};

// /**
//  * Duplicate reference reducer.
//  * @function  duplicateReference
//  * @param {Object} state Current state.
//  * @param {Object} action Action to be handled.
//  * @returns {Object} New state.
//  */
export function duplicateReference(state = duplicateInitialState, action = {}) {
  switch (action.type) {
    case `${DUPLICATE_REFERENCE}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DUPLICATE_REFERENCE}_SUCCESS`:
      return {
        ...state,
        error: null,
        result: action.result,
        loaded: true,
        loading: false,
      };
    case `${DUPLICATE_REFERENCE}_FAIL`:
      return {
        ...state,
        error: action.error,
        result: null,
        loading: false,
        loaded: false,
      };
    case RESET_STATE:
      return {
        ...duplicateInitialState,
      };
    default:
      return state;
  }
}

// /**
//  * Confirm anagrafica reducer.
//  * @function  confirmAnagrafica
//  * @param {Object} state Current state.
//  * @param {Object} action Action to be handled.
//  * @returns {Object} New state.
//  */
export function confirmAnagrafica(state = confirmInitialState, action = {}) {
  switch (action.type) {
    case `${CONFIRM_ANAGRAFICA}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${CONFIRM_ANAGRAFICA}_SUCCESS`:
      return {
        ...state,
        error: null,
        success: true,
        loaded: true,
        loading: false,
      };
    case `${CONFIRM_ANAGRAFICA}_FAIL`:
      return {
        ...state,
        error: action.error,
        success: false,
        loading: false,
        loaded: false,
      };
    case RESET_STATE:
      return {
        ...confirmInitialState,
      };
    default:
      return state;
  }
}
