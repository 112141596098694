/**
 * StudioAssociatoView view component.
 * @module components/views/RaggruppamentoTemporaneoView
 */

import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import {
  SideMenu,
  PageHeader,
  SkipToMainContent,
  ContentTypeViewSections,
  ServizioPlaceholderAfterContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  Anagrafica,
  Metadati,
  Persona,
  Allegati,
} from '@package/components/views/Commons';

export const RaggruppamentoTemporaneoViewSectionsOrder = (props) => [
  {
    /* Anagrafica */
    component: Anagrafica,
    props: { moment: props.moment, show_albo_info: false },
  },
  {
    /* Mandataria */
    component: Persona,
    props: {
      moment: props.moment,
      sectionTitle: 'Mandataria',
      folderTitle: 'componenti',
      addType: 'Mandataria',
    },
  },
  {
    /* Mandante */
    component: Persona,
    props: {
      moment: props.moment,
      sectionTitle: 'Mandante',
      folderTitle: 'componenti',
      addType: 'Mandante',
    },
  },
  {
    /* Allegati e modulistica */ component: Allegati,
    props: {
      sectionTitle: 'File allegati e modulistica',
      folderTitle: 'filefolder',
      addType: 'AlboFile',
    },
  },
  {
    /* Referenze */ component: Allegati,
    props: {
      sectionTitle: 'Referenze del fornitore',
      folderTitle: 'referenze',
      addType: 'Referenza',
    },
  },
  { /* Metadati  */ component: Metadati },
];

/**
 * RaggruppamentoTemporaneoView view component class.
 * @function RaggruppamentoTemporaneoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const RaggruppamentoTemporaneoView = ({ content, moment }) => {
  const intl = useIntl();
  const Moment = moment.default;
  Moment.locale(intl.locale);

  const documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container organizzazione-view px-4 my-4">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={false}
          show_actions={false}
        />

        <div className="row row-column-border row-column-menu-left side-menu-container">
          <aside className="col-lg-4 ">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>

          <section
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={RaggruppamentoTemporaneoViewSectionsOrder({
                moment: Moment,
              })}
            />
          </section>
        </div>
      </div>
      <ServizioPlaceholderAfterContent content={content} />
    </>
  );
};

RaggruppamentoTemporaneoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default injectLazyLibs(['moment'])(RaggruppamentoTemporaneoView);
