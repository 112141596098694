/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const LOAD_EXCEL = 'LOAD_EXCEL';
export const RESET_STATE = 'RESET_STATE';
export const DUPLICATE_REFERENCE = 'DUPLICATE_REFERENCE';
export const CONFIRM_ANAGRAFICA = 'CONFIRM_ANAGRAFICA';
