import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'design-react-kit/dist/design-react-kit';
import { useHistory } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { deleteContent } from '@plone/volto/actions';

const messages = defineMessages({
  add_new: {
    id: 'add_new',
    defaultMessage: 'Aggiungi',
  },
  edit_content: {
    id: 'edit_content',
    defaultMessage: 'Modifica',
  },
  delete_content: {
    id: 'delete_content',
    defaultMessage: 'Elimina',
  },
  delete_anagrafica: {
    id: 'delete_anagrafica',
    defaultMessage: 'Sei sicuro di voler eliminare questo contenuto?',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma',
  },
  annulla: {
    id: 'annulla',
    defaultMessage: 'Annulla',
  },
  deleteMessage: {
    id: 'deleteMessage',
    defaultMessage:
      "Premere conferma per eliminare questo contenuto. L'operazione non è reversibile",
  },
});

const AddEditLink = ({ mode, link, customTitle = '', visible = true }) => {
  const [confirm, setConfirm] = useState(false);
  const history = useHistory();
  const toggle = () => setConfirm(!confirm);
  const intl = useIntl();
  const handleConfirm = async () => {
    await dispatch(deleteContent(link));
    toggle();
    history.push(flattenToAppURL(link.substring(0, link.lastIndexOf('/'))));
  };
  const dispatch = useDispatch();

  const handleDelete = () => {
    if (mode === 'delete') setConfirm(true);
  };
  if (!visible) return null;
  const icon =
    mode === 'add'
      ? ['fal', 'plus']
      : mode === 'edit'
      ? ['fas', 'pen']
      : ['fas', 'trash'];
  const title =
    mode === 'add'
      ? intl.formatMessage(messages.add_new)
      : mode === 'edit'
      ? intl.formatMessage(messages.edit_content)
      : intl.formatMessage(messages.delete_content);
  return (
    <UniversalLink
      href={mode !== 'delete' ? link : '#'}
      className="add-edit-link"
      onClick={handleDelete}
    >
      <FontAwesomeIcon title={title} icon={icon} className="mr-2" />
      {customTitle || title}
      <Modal
        isOpen={confirm}
        toggle={toggle}
        withIcon
        labelledBy="deleteConfirmModal"
      >
        <ModalHeader icon="it-info-circle" id="deleteConfirmModal">
          {intl.formatMessage(messages.delete_anagrafica)}
        </ModalHeader>
        <ModalBody>
          <p>{intl.formatMessage(messages.deleteMessage)}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {intl.formatMessage(messages.annulla)}
          </Button>
          <Button color="primary" onClick={handleConfirm}>
            {intl.formatMessage(messages.confirm)}
          </Button>
        </ModalFooter>
      </Modal>
    </UniversalLink>
  );
};

AddEditLink.propTypes = {
  mode: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  customTitle: PropTypes.string,
  visible: PropTypes.bool,
};

export default AddEditLink;
