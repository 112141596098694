import { useState, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { useDebouncedEffect } from 'design-comuni-plone-theme/helpers';
import { PageHeader } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Metadati } from '@package/components/views/Commons';
import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import TablePagination from '../../TablePagination/TablePagination';
import MessageContainer from '../../MessageContainer/MessageContainer';
import cx from 'classnames';

const AreaProfessionistiView = ({ content, location }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Redux - loading
  const isResultLoading = useSelector((state) => state.search.loading);
  // Search state
  const searchResults = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  //pagination
  const userRole = useSelector((state) => state.users.user.roles);

  const isManager = userRole?.includes('Manager');

  const [currentPage, setCurrentPage] = useState(1);
  const bSize = 20;
  const bStart = (currentPage - 1) * bSize;

  //* Calcolo numero pagine

  const pageNumbers = [];
  const showPagination = Math.ceil(totResults / bSize) > 1;

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  const [searchField, setSearchField] = useState('');
  const noFiltersSet = searchField === '';

  // Search function
  const onChangeSearch = (event) => {
    const searchFieldString = event?.target?.value;
    setSearchField(searchFieldString);
  };

  //sort functions
  const [sortTypeOrder, setSortTypeOrder] = useState('');
  const [sortOrder, setSortOrder] = useState('ascending');

  const changeSorting = () => {
    sortOrder === 'ascending'
      ? setSortOrder('descending')
      : setSortOrder('ascending');
  };

  useDebouncedEffect(
    () => {
      dispatch(
        searchContent(location.pathname, {
          'path.depth': 1,
          SearchableText: searchField,
          sort_on: sortTypeOrder,
          sort_order: sortOrder,
          b_size: bSize,
          b_start: bStart,
        }),
      );
      return () => {
        dispatch(resetSearchContent());
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    500,
    [
      dispatch,
      location.pathname,
      searchField,
      bSize,
      bStart,
      sortOrder,
      sortTypeOrder,
    ],
  );
  //icons
  const searchIcon = ['fas', 'search'];

  const SortIcon = ({ iconType, sortOrder }) => {
    const icon = useMemo(
      () =>
        iconType === sortTypeOrder
          ? sortOrder === 'ascending'
            ? ['fa', 'sort-up']
            : ['fa', 'sort-down']
          : ['fa', 'sort-up'],
      [iconType, sortOrder],
    );

    return <FontAwesomeIcon className="sort-icon ml-1" icon={icon} />;
  };

  return (
    <div className="container px-4 my-4 mb-5">
      <PageHeader
        content={content}
        readingtime={null}
        showreadingtime={false}
        showdates={false}
        showtassonomiaargomenti={false}
        show_actions={false}
        addLink={false}
      />
      {isManager && (
        <div className="export-button-group py-3 mb-2">
          <a
            href={`${location.pathname}/@@download/export-referenze`}
            className="button-item-container mr-3"
          >
            Esporta referenze
          </a>
          <a
            href={`${location.pathname}/@@download/export-anagrafiche`}
            className="button-item-container"
          >
            Esporta anagrafiche
          </a>
        </div>
      )}
      {isManager && (
        <div className="search-box-group">
          <div className="input-group-icon">
            <FontAwesomeIcon icon={searchIcon} />
          </div>
          <input
            className="search-box"
            type="search"
            placeholder={intl.formatMessage(messages.cerca)}
            onChange={onChangeSearch}
            value={searchField}
          />
        </div>
      )}
      <div className="folderContentsContainer">
        <div className="folderContentsTable">
          <div className="tableRow tableHeader">
            <div className="col-1">
              <button
                className={cx('orderingSelector', {
                  ascending:
                    sortTypeOrder === 'fornitore_code' &&
                    sortOrder === 'ascending',
                  descending:
                    sortTypeOrder === 'fornitore_code' &&
                    sortOrder === 'descending',
                })}
                onClick={() => {
                  setSortTypeOrder('fornitore_code');
                  changeSorting();
                }}
              >
                {intl.formatMessage(messages.thId)}
                <SortIcon iconType="fornitore_code" sortOrder={sortOrder} />
              </button>
            </div>
            <div className="col-5">
              <button
                className={cx('orderingSelector', {
                  ascending:
                    sortTypeOrder === 'portal_type' &&
                    sortOrder === 'ascending',
                  descending:
                    sortTypeOrder === 'portal_type' &&
                    sortOrder === 'descending',
                })}
                onClick={() => {
                  setSortTypeOrder('portal_type');
                  changeSorting();
                }}
              >
                {intl.formatMessage(messages.thType)}
                <SortIcon iconType="portal_type" sortOrder={sortOrder} />
              </button>
            </div>
            <div className="col-6">
              <button
                className={cx('orderingSelector', {
                  ascending:
                    sortTypeOrder === 'sortable_title' &&
                    sortOrder === 'ascending',
                  descending:
                    sortTypeOrder === 'sortable_title' &&
                    sortOrder === 'descending',
                })}
                onClick={() => {
                  setSortTypeOrder('sortable_title');
                  changeSorting();
                }}
              >
                {intl.formatMessage(messages.thTitle)}
                <SortIcon iconType="sortable_title" sortOrder={sortOrder} />
              </button>
            </div>
          </div>

          <div>
            {searchResults?.map((i) => {
              return (
                <div className="tableRow" key={i.id}>
                  <div className="col-1">{i.fornitore_code}</div>

                  <div className="col-5">
                    {i['@type'].replace(/([a-z](?=[A-Z]))/g, '$1 ')}
                  </div>
                  <div className="col-6">
                    <UniversalLink href={flattenToAppURL(i['@id'])}>
                      {i.title}
                    </UniversalLink>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {searchResults.length === 0 && noError && !isResultLoading && (
        <MessageContainer messageType="no-res" />
      )}

      {/* Mostra durante caricamento */}
      {isResultLoading && <MessageContainer messageType="loading" />}

      {/* Mostra quando si verifica un errore */}
      {!noError && <MessageContainer messageType="error" />}
      <div className="pagination-container mt-4">
        <TablePagination
          searchResults={searchResults}
          firstResIndex={firstResIndex}
          lastResIndex={lastResIndex}
          totResults={totResults}
          noFiltersSet={noFiltersSet}
          pageNumbers={pageNumbers}
          currentPage={currentPage}
          lastPage={lastPage}
          setCurrentPage={setCurrentPage}
          showPagination={showPagination}
        />
      </div>
      <Metadati content={content} />
    </div>
  );
};

export default AreaProfessionistiView;

const messages = defineMessages({
  cerca: {
    id: 'cerca',
    defaultMessage: 'Cerca',
  },
  thTitle: {
    id: 'thTitle',
    defaultMessage: 'Titolo',
  },
  thId: {
    id: 'thId',
    defaultMessage: 'Cod.',
  },
  thType: {
    id: 'thType',
    defaultMessage: 'Tipo',
  },
});
