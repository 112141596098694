import React, { useState, useEffect, createRef } from 'react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import {
  Metadati,
  Persona,
  Anagrafica,
} from '@package/components/views/Commons';
import { useIntl } from 'react-intl';
import {
  SideMenu,
  PageHeader,
  SkipToMainContent,
  ContentTypeViewSections,
  ServizioPlaceholderAfterContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import PropTypes from 'prop-types';

export const ConsorziataViewSectionsOrder = (props) => [
  {
    /* Anagrafica */
    component: Anagrafica,
    props: { moment: props.moment, show_albo_info: false, show_title: false },
  },
  {
    /* Legale rappresentante */
    component: Persona,
    props: {
      moment: props.moment,
      sectionTitle: 'Legale rappresentante',
      folderTitle: '',
      addType: 'LegaleRappresentante',
    },
  },

  { /* Metadati  */ component: Metadati },
];
const ConsorziataView = ({ content, moment }) => {
  const intl = useIntl();
  const Moment = moment.default;
  Moment.locale(intl.locale);
  const documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container consorziata-view px-4 my-4">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={false}
          show_actions={false}
        />

        <div className="row row-column-border row-column-menu-left side-menu-container">
          <aside className="col-lg-4 ">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>

          <section
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={ConsorziataViewSectionsOrder({
                moment: Moment,
              })}
            />
          </section>
        </div>
      </div>
      <ServizioPlaceholderAfterContent content={content} />
    </>
  );
};

ConsorziataView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default injectLazyLibs(['moment'])(ConsorziataView);
