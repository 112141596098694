import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';
import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import {
  customSelectStyles,
  DropdownIndicator,
  ClearIndicator,
  Option,
  selectTheme,
  MenuList,
  MultiValueContainer,
} from '@plone/volto/components/manage/Widgets/SelectStyling';

const MacroCategoryWidget = (props) => {
  const { value, onChange, id, ...rest } = props;
  const [updatedValue, setUpdatedValue] = useState(
    value ? JSON.parse(value) : {},
  );
  const [currentOption, setCurrentOption] = useState(
    Object.keys(updatedValue).find((key) => updatedValue[key]) || '',
  );
  const onChangeTerm = (value, category) => {
    let newValues = { ...updatedValue };
    newValues[category] = value;

    setUpdatedValue(newValues);

    onChange(id, JSON.stringify(newValues));
  };
  const availableOptions = Object.keys(updatedValue);
  const handleSelectOption = ({ value }, action) => {
    const oldValue = currentOption;

    setCurrentOption(value);
    onChangeTerm('', oldValue);
  };
  const getImporto = () =>
    updatedValue.hasOwnProperty(currentOption)
      ? updatedValue[currentOption] ?? ''
      : '';
  const options = useMemo(() => {
    const noValue = [
      {
        value: '',
        label: '-- Nessuna opzione --',
      },
    ];
    const computedOptions = availableOptions?.reduce(
      (acc, curr) => [
        ...acc,
        {
          value: curr,
          label: curr,
        },
      ],
      [],
    );
    return noValue.concat(computedOptions);
  }, [availableOptions]);

  return (
    <FormFieldWrapper id={id} className="macroCategoryField" {...rest}>
      <div>
        <Select
          title={'Categoria'}
          id={'category'}
          options={options}
          value={options.find((option) => option.value === currentOption)}
          onChange={handleSelectOption}
          className="react-select-container"
          classNamePrefix="react-select"
          styles={customSelectStyles}
          components={{
            ...(options?.length > 25 && {
              MenuList,
            }),
            MultiValueContainer,
            DropdownIndicator,
            ClearIndicator,
            Option: Option,
          }}
          theme={selectTheme}
        />
        <Input
          id={'value'}
          value={getImporto()}
          required={false}
          onChange={(e, { value }) => onChangeTerm(value, currentOption)}
          key={'macro-category-value'}
          placeholder={'Importo'}
        />
      </div>
    </FormFieldWrapper>
  );
};

MacroCategoryWidget.propTypes = {
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
};

export default MacroCategoryWidget;
