import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Icon,
} from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, confirmAnagrafica } from '@package/actions';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Toast } from '@plone/volto/components';

const messages = defineMessages({
  conferma_anagrafica: {
    id: 'conferma_anagrafica',
    defaultMessage: 'Conferma anagrafica',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma',
  },
  annulla: {
    id: 'annulla',
    defaultMessage: 'Annulla',
  },
  confermaMessage: {
    id: 'confermaMessage',
    defaultMessage: "Premere conferma per confermare l'anagrafica corrente.",
  },
  success: {
    id: 'Success',
    defaultMessage: 'Successo',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Errore',
  },
  conferma_save_error: {
    id: 'conferma_save_error',
    defaultMessage: "Errore durante la conferma dell'anagrafica",
  },
  conferma_save_success: {
    id: 'conferma_save_success',
    defaultMessage: 'Conferma anagrafica avvenuta con successo',
  },
});
const ConfermaAnagrafica = ({ content, toastify }) => {
  const [confirm, setConfirm] = useState(false);
  const toggle = () => setConfirm(!confirm);
  const intl = useIntl();
  const handleConfirm = async () => {
    await dispatch(confirmAnagrafica(content.confirm_anagrafica.action));
    toggle();
  };
  const dispatch = useDispatch();
  const confirmAnagraficaState = useSelector(
    (state) => state.confirmAnagrafica,
  );

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    if (confirmAnagraficaState.error) {
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={intl.formatMessage(messages.conferma_save_error)}
        />,
      );
      setConfirm(false);
    } else if (
      !confirmAnagraficaState.error &&
      confirmAnagraficaState.success
    ) {
      toastify.toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.conferma_save_success)}
        />,
      );
    }
  }, [
    dispatch,
    confirmAnagraficaState.error,
    confirmAnagraficaState.success,
    toastify,
    intl,
  ]);

  if (
    !content?.confirm_anagrafica?.show_button ||
    !content?.confirm_anagrafica?.action ||
    confirmAnagraficaState.success ||
    content.data_confirmed
  )
    return null;
  return (
    <>
      <Button onClick={toggle} icon className="ml-5">
        <Icon
          icon="it-check-circle"
          color={'white'}
          className="mr-2"
          size="md"
        ></Icon>
        {intl.formatMessage(messages.conferma_anagrafica)}
      </Button>
      <Modal
        isOpen={confirm}
        toggle={toggle}
        withIcon
        labelledBy="confermaAnagraficaConfirmModal"
      >
        <ModalHeader icon="it-info-circle" id="confermaAnagraficaConfirmModal">
          {intl.formatMessage(messages.conferma_anagrafica)}
        </ModalHeader>
        <ModalBody>
          <p>{intl.formatMessage(messages.confermaMessage)}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {intl.formatMessage(messages.annulla)}
          </Button>
          <Button color="primary" onClick={handleConfirm}>
            {intl.formatMessage(messages.confirm)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default injectLazyLibs(['toastify'])(ConfermaAnagrafica);
