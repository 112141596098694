/**
 * StudioAssociatoView view component.
 * @module components/views/StudioAssociatoView
 */

import React, { createRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import {
  SideMenu,
  PageHeader,
  SkipToMainContent,
  ContentTypeViewSections,
  ServizioPlaceholderAfterContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  Allegati,
  Anagrafica,
  Metadati,
  Persona,
} from '@package/components/views/Commons';

export const OrganizzazioneViewSectionsOrder = (props) => [
  {
    /* Anagrafica */
    component: Anagrafica,
    props: {
      moment: props.moment,
      show_albo_info: false,
      show_title: ['Societa', 'StudioAssociato'].includes(props.type),
    },
  },
  {
    /* Legale rappresentante */
    component: Persona,
    props: {
      moment: props.moment,
      sectionTitle: 'Legale rappresentante',
      folderTitle: 'persone',
      addType: 'LegaleRappresentante',
    },
  },
  {
    /* Allegati e modulistica */
    component: Allegati,
    props: {
      sectionTitle: 'File allegati e modulistica',
      folderTitle: 'filefolder',
      addType: 'AlboFile',
    },
  },
  {
    /* Referenze */ component: Allegati,
    props: {
      sectionTitle: 'Referenze del fornitore',
      folderTitle: 'referenze',
      addType: 'Referenza',
    },
  },
  { /* Metadati  */ component: Metadati },
];

/**
 * ProfessionistaSingolo view component class.
 * @function StudioAssociatoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const OrganizzazioneView = ({ content, moment }) => {
  const intl = useIntl();
  const Moment = moment.default;
  Moment.locale(intl.locale);

  const createdSections = useMemo(() => {
    const sections = OrganizzazioneViewSectionsOrder({
      moment: Moment,
      type: content?.['@type'],
    });
    if (content?.['@type'] === 'StudioAssociato') {
      sections.splice(2, 0, {
        /* Associato */
        component: Persona,
        props: {
          moment: Moment,
          sectionTitle: 'Associati',
          folderTitle: 'persone',
          addType: 'Associato',
          helpText: (
            <p className="redHelpText">
              Attenzione: sono sufficienti i dati di uno dei rappresentanti
              legali; le informazioni relative agli altri associati saranno
              richieste in sede di gara.
            </p>
          ),
        },
      });
    }
    return sections;
  }, [content, Moment]);

  const documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container organizzazione-view px-4 my-4">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={false}
          show_actions={false}
        />

        <div className="row row-column-border row-column-menu-left side-menu-container">
          <aside className="col-lg-4 ">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>

          <section
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={createdSections}
            />
          </section>
        </div>
      </div>
      <ServizioPlaceholderAfterContent content={content} />
    </>
  );
};

OrganizzazioneView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default injectLazyLibs(['moment'])(OrganizzazioneView);
