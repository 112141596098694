/**
 * Album view component.
 * @module components/theme/View/AlbumView
 */

import React from 'react';
import cx from 'classnames';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import {
  searchContent,
  resetSearchContent,
  getVocabulary,
} from '@plone/volto/actions';

import Image from 'design-comuni-plone-theme/components/Image/Image';
import {
  PageHeaderNav,
  PagePlaceholderTitle,
  RichText,
  richTextHasContent,
  PageHeaderTassonomiaArgomenti,
  Sharing,
  Actions,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

import CategoryButton from './Components/CategoryButton';
import AlphabetNavbar from '../../AlphabetNavbar/AlphabetNavbar';
import ResultCard from '../../ResultCard/ResultCard';

import MessageContainer from '../../MessageContainer/MessageContainer';
import TablePagination from '../../TablePagination/TablePagination';

/**
 * Album view component class.
 * @function PatologieView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */

const PatologieView = ({ content, location }) => {
  const intl = useIntl();

  // *** REDUX UTILS *** \\

  const dispatch = useDispatch();

  const searchResults = useSelector((state) => state.search.items);
  const totResults = useSelector((state) => state.search.total);

  const bodyCategories = useSelector(
    (state) =>
      state.vocabularies['ospedaligalliera.policy.bodypart']?.items ?? [],
  );

  // Redux - loading

  const isResultLoading = useSelector((state) => state.search.loading);

  // Redux - error

  const error = useSelector((state) => state.search.error);
  const noError = error === null;

  // Filters state

  const [searchField, setSearchField] = useState('');
  const [clickButton, setClickButton] = useState('');
  const [filterLetter, setFilterLetter] = useState('');

  // Filters functions

  const onChangeSearch = (event) => {
    const searchFieldString = event.target.value.toLowerCase();
    setSearchField(searchFieldString);
    setCurrentPage(1);
  };

  const onClickChange = (title) => {
    const selectedBodyCategory = title;
    setClickButton(selectedBodyCategory);
    setCurrentPage(1);
  };

  const onClickFilter = (letter) => {
    const selectedLetter = letter;
    setFilterLetter(selectedLetter);
    setCurrentPage(1);
  };

  // Filters reset functions

  const resetLetterFilter = () => {
    setFilterLetter('');
  };

  const resetButtonFilter = () => {
    setClickButton('');
  };

  const clearFilters = () => {
    setSearchField('');
    setClickButton('');
    setFilterLetter('');
  };

  const noFiltersSet =
    searchField === '' && clickButton === '' && filterLetter === '';

  const showClearButton = searchField || clickButton || filterLetter;

  // ** PAGINATION ** \\

  const bSize = 15;

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totResults / bSize); i++) {
    pageNumbers.push(i);
  }
  const [currentPage, setCurrentPage] = useState(1);

  const lastPage = pageNumbers.length;

  const lastResIndex = bSize * currentPage;

  const firstResIndex = bSize * (currentPage - 1) + 1;

  const bStart = (currentPage - 1) * bSize;

  //** REDUX STATE FUNCTIONS **/

  useEffect(() => {
    dispatch(
      searchContent(location.pathname, {
        'path.depth': 2,
        SearchableText: searchField,
        bodyparts: clickButton,
        startswith: filterLetter,
        portal_type: 'Patologia',
        b_size: bSize,
        b_start: bStart,
        sort_on: 'sortable_title',
        sort_order: 'ascending',
      }),
    );

    return () => {
      dispatch(resetSearchContent());
    };
  }, [
    dispatch,
    location.pathname,
    searchField,
    clickButton,
    filterLetter,
    bStart,
  ]);

  useEffect(() => {
    dispatch(
      getVocabulary({ vocabNameOrURL: 'ospedaligalliera.policy.bodypart' }),
    );
  }, [dispatch]);

  // * Header const

  const rightHeaderHasContent =
    content.image?.scales ||
    richTextHasContent(content.info_testata) ||
    content.mostra_navigazione ||
    content?.tassonomia_argomenti?.length > 0 ||
    content.mostra_bottoni_condivisione;

  return (
    <>
      <div
        className="patologie-page-container ui container px-4"
        id="page-document"
      >
        <div className="patologie-page">
          {/*-----Testata-----*/}
          <div className="page-header box-shadow PageHeaderWrapper mb-4">
            <div className="row">
              <div
                className={cx('title-description-wrapper', {
                  'col-lg-6': rightHeaderHasContent,
                  'col-lg-12': !rightHeaderHasContent,
                })}
              >
                <PagePlaceholderTitle content={content}>
                  <h1 className="mb-3">{content?.title}</h1>
                </PagePlaceholderTitle>

                <p className="description">{content?.description}</p>

                <div className="search-box-group header-text">
                  <div className="input-group-icon">
                    <Icon icon="magnifying-glass" />
                  </div>
                  <input
                    className="search-box"
                    type="search"
                    placeholder={intl.formatMessage(
                      messages.textFilterPlaceholder,
                    )}
                    onChange={onChangeSearch}
                    value={searchField}
                  />
                </div>
              </div>
              {rightHeaderHasContent && (
                <div className="col-lg-4 offset-lg-2">
                  {content.mostra_bottoni_condivisione && (
                    <div className="px-4 mb-4">
                      <Sharing url={content['@id']} title={content.title} />
                      <Actions url={content['@id']} title={content.title} />
                    </div>
                  )}
                  {content.image?.scales && (
                    <div className="header-image px-4 mb-3">
                      <Image
                        image={content.image}
                        alt={content.title}
                        maxSize={300}
                        key={content.image?.download}
                      />
                    </div>
                  )}
                  {richTextHasContent(content.info_testata) && (
                    <div className="header-infos px-4 mb-5">
                      <RichText serif={false} content={content.info_testata} />
                    </div>
                  )}

                  {content.mostra_navigazione && (
                    <PageHeaderNav
                      content={content}
                      title={intl.formatMessage(messages.inThisSection)}
                    />
                  )}
                  {content?.tassonomia_argomenti?.length > 0 && (
                    <div className="px-4">
                      <PageHeaderTassonomiaArgomenti content={content} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="categories-container">
            <div className="categories-section-title">
              <h3>{intl.formatMessage(messages.bodyCategoriesTitle)}</h3>
            </div>
            <CategoryButton
              bodyCategories={bodyCategories}
              onClickHandler={onClickChange}
              clickButton={clickButton}
              resetButtonFilter={resetButtonFilter}
            />
          </div>

          <div className="navbar-search-container">
            <div className="categories-section-title">
              <h3>{intl.formatMessage(messages.alphabetNavbarTitle)}</h3>
            </div>
            <div className="alphabet-navbar-section">
              <AlphabetNavbar
                onClickHandler={onClickFilter}
                filterLetter={filterLetter}
                resetLetterFilter={resetLetterFilter}
              />
            </div>
          </div>

          <div className="search-result-container">
            {showClearButton && (
              <div className="clear-button-container">
                <button className="clear-button" onClick={clearFilters}>
                  Pulisci filtri
                </button>
              </div>
            )}

            {/* Mostra risultati */}
            {!isResultLoading && noError && searchResults.length > 0 && (
              <div className="container">
                <ResultCard searchResults={searchResults} />
              </div>
            )}

            {/* Mostra paginazione */}
            {!isResultLoading && searchResults.length !== 0 && (
              <div className="pagination-container">
                <TablePagination
                  searchResults={searchResults}
                  firstResIndex={firstResIndex}
                  lastResIndex={lastResIndex}
                  totResults={totResults}
                  noFiltersSet={noFiltersSet}
                  pageNumbers={pageNumbers}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastPage={lastPage}
                />
              </div>
            )}

            {searchResults.length === 0 && noError && !isResultLoading && (
              <MessageContainer messageType="no-res" />
            )}
            {/* Mostra durante caricamento */}
            {isResultLoading && <MessageContainer messageType="loading" />}

            {/* Mostra quando si verifica un errore */}
            {!noError && <MessageContainer messageType="error" />}
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PatologieView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         *
        url: PropTypes.string,
        /**
         * Image of the item
         */
        image: PropTypes.object,
        /**
         * Image caption of the item
         */
        image_caption: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default PatologieView;

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
  inThisSection: {
    id: 'In this section',
    defaultMessage: 'In questa sezione',
  },
  modified: {
    id: 'modified',
    defaultMessage: 'Ultimo aggiornamento',
  },
  textFilterPlaceholder: {
    id: 'textFilterPlaceholder',
    defaultMessage: 'Cerca patologia',
  },
  bodyCategoriesTitle: {
    id: 'bodyCategoriesTitle',
    defaultMessage: 'Cerca patologia per zona del corpo',
  },
  alphabetNavbarTitle: {
    id: 'alphabetNavbarTitle',
    defaultMessage: 'Patologie in ordine alfabetico',
  },
  leggiLink: {
    id: 'leggiLink',
    defaultMessage: 'Leggi',
  },
  noResults: {
    id: 'noResults',
    defaultMessage: 'La ricerca non ha prodotto nessun risultato',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Caricamento risultati...',
  },
  loadingError: {
    id: 'loadingError',
    defaultMessage: 'Si è verificato un errore durante il caricamento',
  },
});
