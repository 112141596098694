import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '@package/components/Logo/logo.png?width=484';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const Logo = () => (
  <figure className="icon mb-0">
    <img src={logo} alt="" width="242" height="122" />
  </figure>
);

export default Logo;
