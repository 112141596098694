import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import {
  Card,
  CardBody,
  // Chip,
  // ChipLabel,
} from 'design-react-kit/dist/design-react-kit';
const messages = defineMessages({
  dates_and_deadlines: {
    id: 'dates_and_deadlines',
    defaultMessage: 'Date e scadenze',
  },
  start_date: {
    id: 'start_date',
    defaultMessage: 'Data inizio lavori',
  },
  end_date: {
    id: 'end_date',
    defaultMessage: 'Data fine lavori',
  },
  approval_date: {
    id: 'approval_date',
    defaultMessage: 'Data di approvazione',
  },
});

const ReferenzaDateEScadenze = ({ content }) => {
  const intl = useIntl();

  // const currentYear = new Date().getFullYear();
  // const isClosed = currentYear > content.to_date.title;

  return (
    <article
      id={'date-e-scadenze'}
      className="it-page-section anchor-offset mt-5 date-e-scadenze"
      menu_title={intl.formatMessage(messages.dates_and_deadlines)}
    >
      <div className="section-header">
        <h5 id={`header-date-e-scadenze`}>
          {intl.formatMessage(messages.dates_and_deadlines)}
        </h5>
      </div>
      <div className="section-content">
        {/* TODO: to be changed when backend data is available */}
        <div className="col-6">
          <Card className="card-bg date-e-scadenze-card">
            <CardBody>
              {/* <div className="card-item">
                <strong>Stato di avanzamento: </strong>{' '}
                <Chip simple>
                  {isClosed === true ? (
                    <ChipLabel>TERMINATO</ChipLabel>
                  ) : (
                    <ChipLabel>APERTO</ChipLabel>
                  )}
                </Chip>
              </div> */}
              <div className="card-item">
                <strong>{intl.formatMessage(messages.start_date)}:</strong>
                <p>{content.from_date?.title}</p>
              </div>
              <div className="card-item">
                <strong>{intl.formatMessage(messages.end_date)}:</strong>
                <p>{content.to_date?.title}</p>
              </div>
              <div className="card-item">
                <strong>{intl.formatMessage(messages.approval_date)}:</strong>
                <p>{content.date?.title}</p>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </article>
  );
};

ReferenzaDateEScadenze.propTypes = {
  content: PropTypes.object,
};

export default ReferenzaDateEScadenze;
