import { AddEditLink } from '@package/components/views/Commons';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getAddableByContext } from '@package/helpers';

const SHOW_NESTED = ['Consorziata', 'Mandante', 'Mandataria'];

const Persona = ({ content, sectionTitle, addType, folderTitle, helpText }) => {
  const location = useLocation();
  const isAddable = getAddableByContext(addType, content);

  return (
    <article
      id={folderTitle + addType}
      className={`it-page-section anchor-offset mt-5 ${folderTitle} persona-section`}
      menu_title={sectionTitle}
    >
      <div className="container box-container persona-container">
        <div className="section-header mb-2">
          <h5 id={`header-${folderTitle + addType}`}>{sectionTitle}</h5>
          <AddEditLink
            mode="add"
            link={flattenToAppURL(
              `${location.pathname}/${folderTitle}/add?type=${addType}`,
            )}
            visible={isAddable}
          />
        </div>
        {helpText && helpText}
        {content?.persone_anagrafica &&
          content?.persone_anagrafica?.map((persona) => {
            if (
              addType === persona['@type'] &&
              content['@type'] !== 'Consorziata'
            ) {
              return (
                <div className="section-content mb-2" key={persona['@id']}>
                  <FontAwesomeIcon icon="user-alt" className="mr-3" />{' '}
                  <UniversalLink href={persona['@id']}>
                    {persona.title ? persona.title : persona.id}
                  </UniversalLink>
                </div>
              );
            } else return null;
          })}
        {content?.componenti_anagrafica &&
          content?.componenti_anagrafica?.[0]?.items?.map((persona) => {
            if (
              addType === persona['@type'] &&
              content['@type'] !== 'Consorziata'
            ) {
              return (
                <div className="section-content mb-2" key={persona['@id']}>
                  <FontAwesomeIcon icon="user-alt" className="mr-3" />{' '}
                  <UniversalLink href={persona['@id']}>
                    {persona.title ? persona.title : persona.id}
                  </UniversalLink>
                  {SHOW_NESTED.includes(persona['@type']) &&
                    persona.legali_rappresentanti?.map((lr) => (
                      <div key={lr.id} className="mt-2">
                        <FontAwesomeIcon
                          icon="user-alt"
                          className="mr-3 ml-4"
                        />
                        <UniversalLink href={lr.url} className>
                          {lr.title}
                        </UniversalLink>
                      </div>
                    ))}
                </div>
              );
            } else return null;
          })}
        {['Consorziata', 'Mandante', 'Mandataria'].includes(content['@type']) &&
          content?.items?.map((persona) => {
            if (addType === persona['@type']) {
              return (
                <div className="section-content mb-2" key={persona['@id']}>
                  <FontAwesomeIcon icon="user-alt" className="mr-3" />{' '}
                  <UniversalLink href={persona['@id']}>
                    {persona.title ? persona.title : persona.id}
                  </UniversalLink>
                </div>
              );
            } else return null;
          })}
      </div>
    </article>
  );
};

export default Persona;
