/**
 * ProfessionistaSingoloView view component.
 * @module components/views/ProfessionistaSingoloView
 */

import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import {
  SideMenu,
  PageHeader,
  ServizioPlaceholderAfterContent,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import { Metadati } from '@package/components/views/Commons';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { UniversalLink } from '@plone/volto/components';
import {
  Card,
  CardBody,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import { getFileExtension } from '@package/helpers';

const messages = defineMessages({
  descrizione: {
    id: 'descrizione',
    defaultMessage: 'Descrizione',
  },
  categorizzazione: {
    id: 'categorizzazione',
    defineMessage: 'Categorizzazione',
  },
  'File allegato': {
    id: 'File allegato',
    defineMessage: 'File allegato',
  },
});
/**
 * Allegato view component class.
 * @function AllegatoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const AllegatoView = ({ content, moment }) => {
  const intl = useIntl();
  const Moment = moment.default;
  Moment.locale(intl.locale);

  const documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container px-4 my-4">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={false}
          show_actions={false}
          editLink={`${flattenToAppURL(content['@id'])}/edit`}
          deleteLink={`${flattenToAppURL(content['@id'])}`}
        />

        <div className="row row-column-border row-column-menu-left side-menu-container">
          <aside className="col-lg-4 ">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>
          <section
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
            ref={documentBody}
          >
            {/* SEZIONI */}
            {content.description && (
              <article
                id={'descrizione'}
                className="it-page-section anchor-offset mt-5 descrizione"
                menu_title={intl.formatMessage(messages.descrizione)}
              >
                <div className="section-header">
                  <h5 id={`header-descrizione`}>
                    {intl.formatMessage(messages.descrizione)}
                  </h5>
                  <p className="documentDescription text-serif mt-3">
                    {content.description}
                  </p>
                </div>
              </article>
            )}
            {/* Not implemented */}
            {/* <article
              id={'categorizzazione'}
              className="it-page-section anchor-offset mt-5 categorizzazione"
              menu_title={intl.formatMessage(messages.categorizzazione)}
            >
              <div className="section-header">
                <h5 id={`header-categorizzazione`}>
                  {intl.formatMessage(messages.categorizzazione)}
                </h5>
                Ciccio info file
              </div>
            </article> */}
            <article
              id={'file-allegato'}
              className="it-page-section anchor-offset mt-5 allegato"
              menu_title={intl.formatMessage(messages['File allegato'])}
            >
              <div className="section-header">
                <h5 id={`header-file-allegato`}>
                  {intl.formatMessage(messages['File allegato'])}
                </h5>
                <Card
                  className="card card-teaser shadow p-4 mt-3 rounded attachment"
                  noWrapper={true}
                  tag="div"
                  key={content.UID}
                >
                  <FontAwesomeIcon
                    title={content.title}
                    icon={getFileExtension(content.file['content-type'])}
                    className="mr-2"
                  />
                  <CardBody tag="div">
                    <CardTitle tag="h5">
                      <UniversalLink
                        title={`Scarica ${content.title}`}
                        href={flattenToAppURL(content.file.download)}
                      >
                        {content.title}
                      </UniversalLink>
                    </CardTitle>
                  </CardBody>
                </Card>
              </div>
            </article>
            <Metadati content={content} />
          </section>
        </div>
      </div>
      <ServizioPlaceholderAfterContent content={content} />
    </>
  );
};

AllegatoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default injectLazyLibs(['moment'])(AllegatoView);
