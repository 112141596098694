/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from 'design-comuni-plone-theme/reducers';

import loadExcel from './loadExcel/loadExcel';
import { duplicateReference, confirmAnagrafica } from './alboReducers/index';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  loadExcel,
  duplicateReference,
  confirmAnagrafica,
};

export default reducers;
