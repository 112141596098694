import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
const messages = defineMessages({
  ruolo_svolto: {
    id: 'ruolo_svolto',
    defaultMessage: 'Ruolo svolto',
  },
});

const ReferenzaRuolo = ({ content }) => {
  const intl = useIntl();
  return (
    <article
      id={'ruolo-svolto'}
      className="it-page-section anchor-offset mt-5 ruolo-svolto"
      menu_title={intl.formatMessage(messages.ruolo_svolto)}
    >
      <div className="section-header">
        <h5 id={`header-ruolo-svolto`}>
          {intl.formatMessage(messages.ruolo_svolto)}
        </h5>
      </div>
      <div className="section-content">
        <p>{content?.role?.title}</p>
      </div>
    </article>
  );
};

ReferenzaRuolo.propTypes = {
  content: PropTypes.object,
};

export default ReferenzaRuolo;
