import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';

import {
  Card,
  CardTitle,
  CardBody,
  Chip,
  ChipLabel,
  Row,
  Col,
} from 'design-react-kit/dist/design-react-kit';
const messages = defineMessages({
  macro_category: {
    id: 'macro_category',
    defaultMessage: "Macro categoria dell'opera",
  },
  type: {
    id: 'type',
    defaultMessage: 'Tipologia',
  },
  amount: {
    id: 'amount',
    defaultMessage: 'Importo',
  },
  work_done: {
    id: 'work_done',
    defaultMessage: 'Prestazioni svolte',
  },
});

const ReferenzaMacrocategoria = ({ content }) => {
  const intl = useIntl();

  const categoryData = JSON.parse(content.specific_category);
  const category = JSON.parse(content.macro_category);

  return (
    <article
      id={'macrocategoria'}
      className="it-page-section anchor-offset mt-5 macrocategoria"
      menu_title={intl.formatMessage(messages.macro_category)}
    >
      <div className="section-header">
        <h5 id={`header-macrocategoria`}>
          {intl.formatMessage(messages.macro_category)}
        </h5>
      </div>
      <div className="section-content">
        <div className="content-details">
          <div className="tipologia">
            <h6>{intl.formatMessage(messages.type)}</h6>
            <Chip simple>
              <ChipLabel>
                {Object.keys(category).filter((key) => category[key] !== null)}
              </ChipLabel>
            </Chip>
          </div>
          <div className="importo">
            <h6>{intl.formatMessage(messages.amount)}</h6>
            <p>
              {Object.values(category).filter((key) => category[key] !== null)}{' '}
              €
            </p>
          </div>
        </div>

        <div className="content-cards">
          <Row>
            {categoryData.map((el) => {
              if (el.value !== null) {
                return (
                  <Col className="col-lg-6 mb-3" key={el.title}>
                    <Card className="card-bg">
                      <CardBody>
                        <CardTitle>
                          <h6>{el.label}</h6>
                        </CardTitle>
                        <div>
                          <strong>{intl.formatMessage(messages.amount)}</strong>
                          <p>{el.value} €</p>
                        </div>
                        <div>
                          <strong>
                            {intl.formatMessage(messages.work_done)}
                          </strong>
                          <ul>
                            {Object.keys(el)
                              .filter((key) => el[key] === true)
                              .map((e) => (
                                <li>{e}</li>
                              ))}
                          </ul>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              } else return <></>;
            })}
          </Row>
        </div>
      </div>
    </article>
  );
};

ReferenzaMacrocategoria.propTypes = {
  content: PropTypes.object,
};

export default ReferenzaMacrocategoria;
