import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  richTextHasContent,
  RichText,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';


const messages = defineMessages({
  descrizione: {
    id: 'descrizione',
    defaultMessage: 'Descrizione',
  },
  descrizione_estesa: {
    id: 'descrizione_estesa',
    defaultMessage: 'Descrizione intervento',
  }
});

const ReferenzaDescrizione = ({ content }) => {
  const intl = useIntl();
  return (content.descrizione || richTextHasContent(content.text)) && (
    <article
      id={'descrizione'}
      className="it-page-section anchor-offset mt-5 dscrizione"
      menu_title={intl.formatMessage(messages.descrizione)}
    >
      <div className="section-header">
        <h5 id={`header-descrizione`}>
          {intl.formatMessage(messages.descrizione)}
        </h5>
      </div>
      {content.descrizione && <div className="section-content">
        <p>{content.description}</p>
      </div>}
      {richTextHasContent(content.text) && <div className="section-content">
        <RichText content={content.text} />
      </div>}

    </article>
  );
};

ReferenzaDescrizione.propTypes = {
  content: PropTypes.object,
};

export default ReferenzaDescrizione;
