import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  Card,
  CardBody,
  CardTitle,
  Button,
} from 'design-react-kit/dist/design-react-kit';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { useGetFolderContents, getFileExtension } from '@package/helpers';
import { AddEditLink } from '@package/components/views/Commons';

const messages = defineMessages({
  file_allegati_modulistica: {
    id: 'file_allegati_modulistica',
    defaultMessage: 'File allegati e modulistica',
  },
  mostra_tutti: {
    id: 'mostra_tutti',
    defaultMessage: 'Mostra tutti',
  },
  add_new: {
    id: 'add_new',
    defaultMessage: 'Aggiungi',
  },
  no_results: {
    id: 'no_results',
    defaultMessage: '{no_result_type} {type} disponibile',
  },
  results_text: {
    id: 'results_text',
    defaultMessage: '{type} recenti',
  },
  allegatis: {
    id: 'allegatis',
    defaultMessage: 'Allegati',
  },
  referenze: {
    id: 'referenze',
    defaultMessage: 'Referenze',
  },
  attachment_reference: {
    id: 'attachment_reference',
    defaultMessage: 'referenza',
  },
  attachment_file: {
    id: 'attachment_file',
    defaultMessage: 'allegato',
  },
  no_results_reference: {
    id: 'no_results_reference',
    defaultMessage: 'Nessuna',
  },
  no_results_file: {
    id: 'no_results_file',
    defaultMessage: 'Nessun',
  },
  helpMessageAllegati: {
    id: 'helpMessageAllegati',
    defaultMessage:
      "Avvertenza: è obbligatoria l'aggiunta del cv professionale e delle referenze.",
  },
});

const Allegati = ({ folderTitle, sectionTitle, addType, content }) => {
  const intl = useIntl();
  const location = useLocation();
  const results = useGetFolderContents(content, folderTitle, {
    'path.depth': 1,
    // TODO: capire come sortare per created, questo non va
    sort_on: 'created',
    sort_order: 'asc',
    metadata_fields: '_all',
    fullobjects: 1,
    b_size: 4,
  });
  return (
    <article
      id={folderTitle}
      className={`it-page-section anchor-offset ${folderTitle} attachments-section`}
      menu_title={sectionTitle}
    >
      <div className="container box-container attachments-container">
        <div className="section-header">
          <h5 id={`header-${folderTitle}`}>{sectionTitle}</h5>

          <AddEditLink
            mode="add"
            link={flattenToAppURL(
              `${location.pathname}/${folderTitle}/add?type=${addType}`,
            )}
          />
        </div>
        <p className="mt-2 redHelpText">
          {intl.formatMessage(messages.helpMessageAllegati)}
        </p>
        {results && results?.items?.length !== 0 && (
          <>
            <p className="mt-3">
              {intl.formatMessage(messages.results_text, {
                type:
                  addType === 'Referenza'
                    ? intl.formatMessage(messages.referenze)
                    : intl.formatMessage(messages.allegatis),
              })}
            </p>
            <div className="attachments">
              {results?.items?.map((item) => {
                let itemURL = '#';
                const icon = getFileExtension(item?.file?.['content-type']);
                let title = 'File';
                switch (item['@type']) {
                  case 'File':
                    itemURL = `${item['@id']}/@@download/file`;
                    break;

                  default:
                    itemURL = item['@id'];
                    title = 'Referenza';
                }

                return (
                  <Card
                    className="card card-teaser shadow p-4 mt-3 rounded attachment"
                    noWrapper={true}
                    tag="div"
                    key={item?.['@id']}
                  >
                    <FontAwesomeIcon
                      title={title}
                      icon={icon}
                      className="mr-2"
                    />
                    <CardBody tag="div">
                      <CardTitle tag="h5">
                        <UniversalLink href={itemURL}>
                          {item.title}
                        </UniversalLink>
                      </CardTitle>
                    </CardBody>
                  </Card>
                );
              })}
            </div>
          </>
        )}
        {(!results || results?.items?.length === 0) && (
          <p className="mt-3">
            <i>
              {intl.formatMessage(messages.no_results, {
                no_result_type:
                  addType === 'Referenza'
                    ? intl.formatMessage(messages.no_results_reference)
                    : intl.formatMessage(messages.no_results_file),
                type:
                  addType === 'Referenza'
                    ? intl.formatMessage(messages.attachment_reference)
                    : intl.formatMessage(messages.attachment_file),
              })}
            </i>
          </p>
        )}

        {results?.total > 4 && (
          <Button
            className="btn-icon showMore"
            color="secondary"
            icon={false}
            size={'md'}
            tag="button"
          >
            <UniversalLink
              href={flattenToAppURL(`${location.pathname}/${folderTitle}`)}
            >
              {intl.formatMessage(messages.mostra_tutti)}
            </UniversalLink>
          </Button>
        )}
      </div>
    </article>
  );
};

Allegati.propTypes = {
  folderTitle: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  addType: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

export default Allegati;
