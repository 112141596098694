import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import moment from 'moment';
import { Card, CardBody } from 'design-react-kit/dist/design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useLocation } from 'react-router-dom';
import { AddEditLink } from '@package/components/views/Commons';

const messages = defineMessages({
  anagrafica: {
    id: 'anagrafica',
    defaultMessage: 'Anagrafica',
  },
  edit_anagrafica: {
    id: 'edit_anagrafica',
    defaultMessage: 'Modifica anagrafica',
  },
  delete_anagrafica: {
    id: 'delete_anagrafica',
    defaultMessage: 'Elimina',
  },
  info_generali: {
    id: 'info_generali',
    defaultMessage: 'Informazioni generali',
  },
  iscrizione: {
    id: 'iscrizione',
    defaultMessage: 'Iscrizione',
  },
  info_altro: {
    id: 'info_altro',
    defaultMessage: 'Altre informazioni',
  },
  titolo: {
    id: 'titolo',
    defaultMessage: 'Titolo',
  },
  nome: {
    id: 'nome',
    defaultMessage: 'Nome',
  },
  cognome: {
    id: 'cognome',
    defaultMessage: 'Cognome',
  },
  cf: {
    id: 'cf',
    defaultMessage: 'Codice Fiscale',
  },
  piva: {
    id: 'piva',
    defaultMessage: 'P.IVA',
  },
  residenza: {
    id: 'residenza',
    defaultMessage: 'Residente a',
  },
  sede_legale: {
    id: 'sede_legale',
    defaultMessage: 'Sede legale dello studio',
  },
  cap: {
    id: 'cap',
    defaultMessage: 'Codice postale',
  },
  citta: {
    id: 'citta',
    defaultMessage: 'Città',
  },
  provincia: {
    id: 'provincia',
    defaultMessage: 'Provincia',
  },
  tel: {
    id: 'tel',
    defaultMessage: 'Numero di telefono',
  },
  fax: {
    id: 'fax',
    defaultMessage: 'Numero fax',
  },
  mail: {
    id: 'mail',
    defaultMessage: 'E-mail',
  },
  pec: {
    id: 'pec',
    defaultMessage: 'Posta PEC',
  },
  albo: {
    id: 'albo',
    defaultMessage: 'Albo',
  },
  albo_citta: {
    id: 'albo_citta',
    defaultMessage: 'Città',
  },
  albo_iscrizione: {
    id: 'albo_iscrizione',
    defaultMessage: 'Numero di iscrizione',
  },
  albo_data: {
    id: 'albo_data',
    defaultMessage: 'Data di iscrizione',
  },
  abilitazione_antincendio: {
    id: 'abilitazione_antincendio',
    defaultMessage: 'Abilitazione antincendio',
  },
  abilitazione_sicurezza: {
    id: 'abilitazione_sicurezza',
    defaultMessage: 'Abilitazione a coordinatore per la sicurezza',
  },
});

const DELETE_NOT_ALLOWED = [
  'StudioAssociato',
  'ProfessionistaSingolo',
  'Consorzio',
  'RaggruppamentoTemporaneo',
  'Societa',
];

const Anagrafica = ({ content, show_albo_info, show_title = true }) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const location = useLocation();
  const noContent =
    (content.personal_title ||
      content.name ||
      content.surname ||
      content.tax_code ||
      content.vat ||
      content.resident ||
      content.officeaddress ||
      content.zicode ||
      content.city ||
      content.district ||
      content.telephone ||
      content.firm_telephone ||
      content.fax ||
      content.firm_fax ||
      content.email ||
      content.firm_email) === (null || '' || undefined);

  const noAlboContent =
    (content.association ||
      content.associationcity ||
      content.subscriptionnumber ||
      content.subscriptiondate) === (null || '' || undefined);

  return (
    <article
      id={'anagrafica'}
      className="it-page-section anchor-offset mt-5 anagrafica"
      menu_title={show_title ? intl.formatMessage(messages.anagrafica) : null}
    >
      <div className="section-header">
        <h5 id={show_title && `header-anagrafica`}>
          {show_title && intl.formatMessage(messages.anagrafica)}
        </h5>

        <div className="actions">
          <AddEditLink
            mode="edit"
            customTitle={intl.formatMessage(messages.edit_anagrafica)}
            link={flattenToAppURL(`${location.pathname}/edit`)}
          />
          {!DELETE_NOT_ALLOWED.includes(content?.['@type']) && (
            <AddEditLink
              mode="delete"
              customTitle={intl.formatMessage(messages.delete_anagrafica)}
              link={flattenToAppURL(`${location.pathname}`)}
            />
          )}
        </div>
      </div>
      {!noContent && (
        <>
          <h5>{intl.formatMessage(messages.info_generali)}</h5>
          <Card
            className="card card-teaser shadow p-4 mt-5 mb-5 rounded"
            noWrapper={true}
            tag="div"
          >
            <CardBody tag="div">
              {content.personal_title && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.titolo)}
                  </div>
                  <div className="anagrafica-row-value">
                    {content.personal_title}
                  </div>
                </div>
              )}
              {content.name && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.nome)}
                  </div>
                  <div className="anagrafica-row-value">{content.name}</div>
                </div>
              )}
              {content.surname && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.cognome)}
                  </div>
                  <div className="anagrafica-row-value">{content.surname}</div>
                </div>
              )}
              {content.tax_code && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.cf)}
                  </div>
                  <div className="anagrafica-row-value">{content.tax_code}</div>
                </div>
              )}
              {content.vat && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.piva)}
                  </div>
                  <div className="anagrafica-row-value">{content.vat}</div>
                </div>
              )}
              {content.resident && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.residenza)}
                  </div>
                  <div className="anagrafica-row-value">{content.resident}</div>
                </div>
              )}
              {content.officeaddress && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.sede_legale)}
                  </div>
                  <div className="anagrafica-row-value">
                    {content.officeaddress}
                  </div>
                </div>
              )}
              {content.zipcode && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.cap)}
                  </div>
                  <div className="anagrafica-row-value">{content.zipcode}</div>
                </div>
              )}
              {content.city && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.citta)}
                  </div>
                  <div className="anagrafica-row-value">{content.city}</div>
                </div>
              )}
              {content.district && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.provincia)}
                  </div>
                  <div className="anagrafica-row-value">{content.district}</div>
                </div>
              )}
              {(content.telephone || content.firm_telephone) && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.tel)}
                  </div>
                  <div className="anagrafica-row-value">
                    {content.telephone && content.telephone}
                    {content.firm_telephone && content.firm_telephone}
                  </div>
                </div>
              )}
              {(content.fax || content.firm_fax) && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.fax)}
                  </div>
                  <div className="anagrafica-row-value">
                    {content.fax && content.fax}
                    {content.firm_fax && content.firm_fax}
                  </div>
                </div>
              )}
              {(content.email || content.firm_email) && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.mail)}
                  </div>
                  <div className="anagrafica-row-value">
                    {content.email && content.email}
                    {content.firm_email && content.firm_email}
                  </div>
                </div>
              )}
              {(content.pec || content.firm_pec) && (
                <div className="anagrafica-row">
                  <div className="anagrafica-row-label">
                    {intl.formatMessage(messages.pec)}
                  </div>
                  <div className="anagrafica-row-value">
                    {content.pec && content.pec}
                    {content.firm_pec && content.firm_pec}
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </>
      )}

      {show_albo_info && !noAlboContent && (
        <>
          <h5>{intl.formatMessage(messages.iscrizione)}</h5>
          <Card
            className="card card-teaser shadow p-4 mt-5 mb-5 rounded"
            noWrapper={true}
            tag="div"
          >
            <CardBody tag="div">
              <div className="anagrafica-row">
                <div className="anagrafica-row-label">
                  {intl.formatMessage(messages.albo)}
                </div>
                <div className="anagrafica-row-value">
                  {content.association === null ? '-' : content.association}
                </div>
              </div>
              <div className="anagrafica-row">
                <div className="anagrafica-row-label">
                  {intl.formatMessage(messages.albo_citta)}
                </div>
                <div className="anagrafica-row-value">
                  {content.associationcity === null
                    ? '-'
                    : content.associationcity}
                </div>
              </div>
              <div className="anagrafica-row">
                <div className="anagrafica-row-label">
                  {intl.formatMessage(messages.albo_iscrizione)}
                </div>
                <div className="anagrafica-row-value">
                  {content.subscriptionnumber === null
                    ? '-'
                    : content.subscriptionnumber}
                </div>
              </div>
              <div className="anagrafica-row">
                <div className="anagrafica-row-label">
                  {intl.formatMessage(messages.albo_data)}
                </div>
                <div className="anagrafica-row-value">
                  {content.subscriptiondate === null
                    ? '-'
                    : moment(content.subscriptiondate).format('DD-MM-YYYY')}
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}

      {(content.antifire_habilitation_radio ||
        content.safety_habilitation_radio) && (
        <div>
          {!noContent && <h5>{intl.formatMessage(messages.info_altro)}</h5>}
          <Card
            className="card card-teaser shadow p-4 mt-5 mb-5 rounded"
            noWrapper={true}
            tag="div"
          >
            <CardBody tag="div">
              <div className="anagrafica-row">
                <div className="anagrafica-row-label">
                  {intl.formatMessage(messages.abilitazione_antincendio)}
                </div>
                <div className="anagrafica-row-value">
                  {content.antifire_habilitation_radio?.token === 'si' ? (
                    <span>Sì</span>
                  ) : (
                    <span>No</span>
                  )}
                </div>
              </div>
              <div className="anagrafica-row">
                <div className="anagrafica-row-label">
                  {intl.formatMessage(messages.abilitazione_sicurezza)}
                </div>
                <div className="anagrafica-row-value">
                  {content.safety_habilitation_radio?.token === 'si' ? (
                    <span>Sì</span>
                  ) : (
                    <span>No</span>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </article>
  );
};

Anagrafica.propTypes = {
  content: PropTypes.object,
};

export default Anagrafica;
