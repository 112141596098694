import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const TermsAndConditionsMiddleware = ({ location, history, token, layout }) => {
  const user = useSelector((state) => {
    return state.users?.user;
  });
  const userLoaded = useSelector((state) => {
    return state.users?.get?.loaded;
  });

  useEffect(() => {
    if (
      userLoaded &&
      token &&
      !user?.roles?.some((r) => r === 'Manager' || r === 'SiteAdministrator') &&
      !user?.agreed_terms &&
      !(
        location.pathname.includes('termini-e-condizioni') ||
        layout === 'terms_and_conditions'
      )
    ) {
      history.replace('/termini-e-condizioni');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, location, userLoaded]);
  return <></>;
};

export default TermsAndConditionsMiddleware;
