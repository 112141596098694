/**
 * ReferenzaView view component.
 * @module components/views/ReferenzaView
 */

import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

import {
  SideMenu,
  PageHeader,
  ServizioPlaceholderAfterContent,
  SkipToMainContent,
  ContentTypeViewSections,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import ReferenzaCommittente from './ReferenzaCommittente';
import ReferenzaRuolo from './ReferenzaRuolo';
import ReferenzaDescrizione from './ReferenzaDescrizione';
import ReferenzaMacrocategoria from './ReferenzaMacrocategoria';
import ReferenzaDateEScadenze from './ReferenzaDateEScadenze';

export const ProfessionistaViewSectionsOrder = (props) => [
  {
    /* Committente */
    component: ReferenzaCommittente,
    props: { moment: props.moment },
  },
  {
    /* Ruolo */
    component: ReferenzaRuolo,
    props: {
      moment: props.moment,
    },
  },
  {
    /* Descrizione */
    component: ReferenzaDescrizione,
    props: {
      moment: props.moment,
    },
  },
  {
    /* Macrocategoria */
    component: ReferenzaMacrocategoria,
    props: {
      moment: props.moment,
    },
  },
  {
    /* Date e scadenze */
    component: ReferenzaDateEScadenze,
    props: {
      moment: props.moment,
    },
  },
];

/**
 * ProfessionistaSingolo view component class.
 * @function ReferenzaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReferenzaView = ({ content, moment }) => {
  const intl = useIntl();
  const Moment = moment.default;
  Moment.locale(intl.locale);

  const documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container px-4 my-4">
        <SkipToMainContent />
        <div className="referenza-page-header">
          <PageHeader
            content={content}
            readingtime={null}
            showreadingtime={false}
            showdates={false}
            showtassonomiaargomenti={false}
            show_actions={false}
            editLink={`${flattenToAppURL(content['@id'])}/edit`}
            deleteLink={`${flattenToAppURL(content['@id'])}`}
          />
        </div>

        <div className="row row-column-border row-column-menu-left side-menu-container">
          <aside className="col-lg-4 ">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>
          <section
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={ProfessionistaViewSectionsOrder({
                moment: Moment,
              })}
            />
          </section>
        </div>
      </div>
      <ServizioPlaceholderAfterContent content={content} />
    </>
  );
};

ReferenzaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default injectLazyLibs(['moment'])(ReferenzaView);
