import React, { useEffect, useState, createRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Anagrafica,
  Metadati,
  Persona,
} from '@package/components/views/Commons';
import { useIntl } from 'react-intl';
import {
  SideMenu,
  PageHeader,
  SkipToMainContent,
  ContentTypeViewSections,
  ServizioPlaceholderAfterContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

export const PGenViewSectionsOrder = (props) => [
  {
    /* Legale rappresentante */
    component: Anagrafica,
    props: {
      moment: props.moment,
      show_albo_info: props.show_albo_info,
      show_title: !['Mandataria', 'Mandante'].includes(props.type),
    },
  },

  { /* Metadati  */ component: Metadati },
];
const ProfessionistaGenericoView = ({ content, moment }) => {
  const intl = useIntl();
  const Moment = moment.default;
  Moment.locale(intl.locale);

  const documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);
  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);
  const createdSections = useMemo(() => {
    const sections = PGenViewSectionsOrder({
      moment: Moment,
      type: content?.['@type'],
    });
    if (['Mandante', 'Mandataria'].includes(content?.['@type'])) {
      sections.splice(1, 0, {
        /* Legale */
        component: Persona,
        props: {
          moment: Moment,
          sectionTitle: 'Legale rappresentante',
          folderTitle: '',
          addType: 'LegaleRappresentante',
        },
      });
    }
    return sections;
  }, [content, Moment]);
  return (
    <>
      <div className="container professionista-generico-view px-4 my-4">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={false}
          show_actions={false}
        />
        <div className="row row-column-border row-column-menu-left side-menu-container">
          <aside className="col-lg-4 ">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>

          <section
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={createdSections}
            />
          </section>
        </div>
      </div>

      <ServizioPlaceholderAfterContent content={content} />
    </>
  );
};

ProfessionistaGenericoView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};

export default injectLazyLibs(['moment'])(ProfessionistaGenericoView);
