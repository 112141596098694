/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from 'design-comuni-plone-theme/config/italiaConfig';
import '@plone/volto/config';

// WIDGETS
import { defaultIconWidgetOptions } from 'design-comuni-plone-theme/helpers/index';

// COMPONENTS
import {
  AreaProfessionistiView,
  ProfessionistaSingoloView,
  AllegatiReferenzeView,
  ReferenzaView,
  AllegatoView,
  OrganizzazioneView,
  ProfessionistaGenericoView,
  ConsorzioView,
  RaggruppamentoTemporaneoView,
  ConsorziataView,
  TermsAndConditionsMiddleware,
  TermsAndConditionsView,
} from '@package/components';

import IconWidget from 'design-comuni-plone-theme/components/ItaliaTheme/manage/Widgets/IconWidget';
import SpecificCategoryWidget from '@package/components/manage/Widgets/SpecificCategoryWidget';
import MacroCategoryWidget from '@package/components/manage/Widgets/MacroCategoryWidget';

const additionalIcons = [...defaultIconWidgetOptions];

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Albo professionisti',
      siteSubtitle: 'Ospedali Galliera - Genova',
      parentSiteTitle: 'Ente Ospedaliero - Ospedali Galliera di Genova',
      parentSiteURL: 'https://www.galliera.it/',
      subsiteParentSiteTitle: 'Ente Ospedaliero - Ospedali Galliera di Genova',
      arLoginUrl: '/login',
      arLogoutUrl: '/logout',
    },
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: TermsAndConditionsMiddleware,
      },
    ],
  };

  config.widgets = {
    ...config.widgets,
    widget: {
      ...config.widgets.widget,
      specific_category: SpecificCategoryWidget,
      macro_category: MacroCategoryWidget,
    },
  };

  //config.blocks.blocksConfig.listing.variations = [
  //  ...config.blocks.blocksConfig.listing.variations,
  //];
  config.views = {
    ...config.views,
    layoutViews: {
      ...config.views.layoutViews,
      // TODO: vedere col backend se ne mettiamo due o ne usiamo solo una magari settata in creazione della struttura della AI
      allegati_modulistica_view: AllegatiReferenzeView,
      referenze_view: AllegatiReferenzeView,
      area_professionisti_view: AreaProfessionistiView,
      terms_and_conditions: TermsAndConditionsView,
    },
    contentTypesViews: {
      ...config.views.contentTypesViews,
      // TODO: change me later
      AreaProfessionisti: AreaProfessionistiView,
      ProfessionistaSingolo: ProfessionistaSingoloView,
      Referenza: ReferenzaView,
      AlboFile: AllegatoView,
      CartellaFile: AllegatiReferenzeView,
      CartellaReferenza: AllegatiReferenzeView,
      CartellaPersone: AllegatiReferenzeView,
      StudioAssociato: OrganizzazioneView,
      Societa: OrganizzazioneView,
      Associato: ProfessionistaGenericoView,
      LegaleRappresentante: ProfessionistaGenericoView,
      GiovaneProfessionista: ProfessionistaGenericoView,
      Consorzio: ConsorzioView,
      RaggruppamentoTemporaneo: RaggruppamentoTemporaneoView,
      Mandataria: ProfessionistaGenericoView,
      Mandante: ProfessionistaGenericoView,
      Consorziata: ConsorziataView,
      // TODO: mettere vista custom per il file, vedere se customizzzare quella base o farne un altra
    },
    layoutViewsNamesMapping: {
      ...config.views.layoutViewsNamesMapping,
      allegati_modulistica_view: 'Allegati e modulistica',
      referenze_view: 'Referenze',
      area_professionisti_view: 'Area professionisti',
      terms_and_conditions: 'Termini e condizioni',
    },
  };

  config.widgets.widget.icon = (props) => (
    <IconWidget {...props} defaultOptions={additionalIcons} />
  );

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text = {
    it: {
      title: 'Usiamo i cookies',
      description:
        "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. I cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Cambia le impostazioni'. Per saperne di più su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='https://alboprofessionisti.galliera.it/cookie-policy' target='_blank'>Cookie Policy.</a>",
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical.choices = [
    ...config.settings[
      'volto-gdpr-privacy'
    ].defaultPanelConfig.technical.choices.filter(
      (f) => f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
    ),
  ];

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling.choices = [
    ...config.settings[
      'volto-gdpr-privacy'
    ].defaultPanelConfig.profiling.choices.filter(
      (f) => f.config_key !== 'VIMEO',
    ),
  ];

  return config;
}
