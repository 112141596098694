import React from 'react';
import PropTypes from 'prop-types';
import { Metadata } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const Metadati = ({ content }) => {
  return <Metadata content={content} showSectionTitle={false} />;
};

Metadati.propTypes = {
  content: PropTypes.object,
};

export default Metadati;
