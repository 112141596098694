import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
const messages = defineMessages({
  committente: {
    id: 'committente',
    defaultMessage: 'Committente',
  },
});

const ReferenzaCommittente = ({ content }) => {
  const intl = useIntl();
  return (
    <article
      id={'committente'}
      className="it-page-section anchor-offset mt-5 committente"
      menu_title={intl.formatMessage(messages.committente)}
    >
      <div className="section-header">
        <h5 id={`header-committente`}>
          {intl.formatMessage(messages.committente)}
        </h5>
      </div>
      <div className="section-content">
        <p>{content.customer}</p>
      </div>
    </article>
  );
};

ReferenzaCommittente.propTypes = {
  content: PropTypes.object,
};

export default ReferenzaCommittente;
