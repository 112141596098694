/**
 * Add your helpers here.
 * @module helpers
 * @example
 * export { Api } from './Api/Api';
 */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { contentFolderHasItems } from 'design-comuni-plone-theme/helpers';
import { countBy, flattenDeep } from 'lodash';

export const useGetFolderContents = (content, folderTitle, queryParams) => {
  const url = content
    ? `${flattenToAppURL(content['@id'])}/${folderTitle}`
    : '';
  const key = folderTitle + url;
  const searchResults = useSelector((state) => state.search.subrequests);
  const dispatch = useDispatch();

  const hasChildren = contentFolderHasItems(content, folderTitle);

  useEffect(() => {
    if (folderTitle && hasChildren) {
      dispatch(
        searchContent(
          url,
          {
            ...queryParams,
          },
          key,
        ),
      );

      return () => {
        dispatch(resetSearchContent(key));
      };
    }
    // eslint-disable-next-line
  }, [key]);

  return searchResults[key] ?? null;
};

export const getFileExtension = (fileType) => {
  switch (fileType) {
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
      return ['far', 'file-image'];
    case 'application/pdf':
      return ['far', 'file-pdf'];
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return ['far', 'file-word'];
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return ['far', 'file-excel'];
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return ['far', 'file-powerpoint'];
    default:
      return ['far', 'file'];
  }
};

const CARDINALITIES = {
  Consorzio: {
    LegaleRappresentante: 1,
    Consorziata: Infinity,
  },
  Consorziata: {
    LegaleRappresentante: 1,
  },
  Mandante: {
    LegaleRappresentante: 1,
  },
  Mandataria: {
    LegaleRappresentante: 1,
  },
  RaggruppamentoTemporaneo: {
    Mandataria: 1,
    Mandante: Infinity,
  },
  Societa: {
    LegaleRappresentante: 1,
  },
  StudioAssociato: {
    LegaleRappresentante: 1,
    Associato: Infinity,
  },
};

export const getAddableByContext = (addType, content) => {
  let currentItems = [];
  // Potremmo essere in uno dei CT che lo hanno allo stesso livello
  // bisogna controllare items
  if (['Consorziata', 'Mandante', 'Mandataria'].includes(content['@type'])) {
    currentItems = [...content.items];
  } else
    currentItems = [
      ...flattenDeep(content?.persone_anagrafica?.map((i) => i) || []),
      ...flattenDeep(
        content?.componenti_anagrafica?.map((i) => i?.items) || [],
      ),
    ];
  // Non so che sta succedendo, esco
  if (currentItems.length === 0) return true;
  const contextType = content['@type'];
  if (contextType in CARDINALITIES) {
    const cardinality = CARDINALITIES[contextType];

    if (!cardinality[addType]) return true;
    const occurrences = countBy(currentItems, '@type')?.[addType] || 0;
    return occurrences < cardinality[addType];
  }
  return true;
};
