import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Icon,
} from 'design-react-kit/dist/design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, duplicateReference } from '@package/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  duplica: {
    id: 'duplica',
    defaultMessage: 'Duplica referenza',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma',
  },
  annulla: {
    id: 'annulla',
    defaultMessage: 'Annulla',
  },
  duplicaMessage: {
    id: 'duplicaMessage',
    defaultMessage:
      'Premere conferma per creare una nuova referenza copiando titolo, committente e descrizione',
  },
  duplicaHeader: {
    id: 'duplicaHeader',
    defaultMessage: 'Conferma duplicazione referenza',
  },
});
const DuplicaReferenza = ({ content }) => {
  const [confirm, setConfirm] = useState(false);
  const toggle = () => setConfirm(!confirm);
  const intl = useIntl();
  const history = useHistory();
  const handleDuplicate = async () => {
    await dispatch(duplicateReference(content['@id']));
    toggle();
  };
  const dispatch = useDispatch();
  const duplicateReferenceState = useSelector(
    (state) => state.duplicateReference,
  );

  useEffect(() => {
    return () => dispatch(resetState());
  }, [dispatch]);
  useEffect(() => {
    const { error, loaded, loading, result } = duplicateReferenceState;
    if (result && loaded && !loading && !error) {
      history.push(flattenToAppURL(`${result['@id']}/edit`));
      dispatch(resetState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateReferenceState]);

  if (content['@type'] !== 'Referenza') return null;
  return (
    <>
      <Button onClick={toggle} icon className="ml-4">
        <Icon
          icon="it-plus-circle"
          color={'white'}
          className="mr-2"
          size="md"
        ></Icon>
        {intl.formatMessage(messages.duplica)}
      </Button>
      <Modal
        isOpen={confirm}
        toggle={toggle}
        withIcon
        labelledBy="duplicaConfirmModal"
      >
        <ModalHeader icon="it-info-circle" id="duplicaConfirmModal">
          {intl.formatMessage(messages.duplicaHeader)}
        </ModalHeader>
        <ModalBody>
          <p>{intl.formatMessage(messages.duplicaMessage)}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {intl.formatMessage(messages.annulla)}
          </Button>
          <Button color="primary" onClick={handleDuplicate}>
            {intl.formatMessage(messages.confirm)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default DuplicaReferenza;
