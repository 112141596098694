/**
 * Users controlpanel user.
 * @module components/manage/Controlpanels/UsersControlpanelUser
 * CUSTOMIZATIONS:
 * 	- add reset password action to actions menu
 *  - custom useeffect and toast handling
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Dropdown, Table, Checkbox } from 'semantic-ui-react';
import trashSVG from '@plone/volto/icons/delete.svg';
import replaceSVG from '@plone/volto/icons/replace.svg';
import { Icon } from '@plone/volto/components';
import ploneSVG from '@plone/volto/icons/plone.svg';
import { resetPassword } from '@plone/volto/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Toast } from '@plone/volto/components';

const messages = defineMessages({
  conferma_anagrafica: {
    id: 'conferma_anagrafica',
    defaultMessage: 'Conferma anagrafica',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma',
  },
  annulla: {
    id: 'annulla',
    defaultMessage: 'Annulla',
  },
  confermaMessage: {
    id: 'confermaMessage',
    defaultMessage: "Premere conferma per confermare l'anagrafica corrente.",
  },
  success: {
    id: 'Success',
    defaultMessage: 'Successo',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Errore',
  },
  conferma_reset_password_error: {
    id: 'conferma_reset_password_error',
    defaultMessage: 'Errore durante il reset della password per {user}',
  },
  conferma_reset_password_success: {
    id: 'conferma_reset_password_success',
    defaultMessage: 'Reset della password per {user} avvenuto con successo',
  },
});

/**
 * UsersControlpanelUser class.
 * @class UsersControlpanelUser
 * @extends Component
 */
class RenderUsers extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    user: PropTypes.shape({
      username: PropTypes.string,
      fullname: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ).isRequired,
    onDelete: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Sharing
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.onChange = this.onChange.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
  }
  /**
   * @param {*} event
   * @param {*} { value }
   * @memberof UsersControlpanelUser
   */

  onChange(event, { value }) {
    const [user, role] = value.split('.');
    this.props.updateUser(user, role);
  }

  onResetPassword() {
    // resetUser password
    this.setState({
      calledOn: this.props.user.id,
    });
    this.props.resetPassword(this.props.user.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.success !== this.props.success &&
      !prevProps.success &&
      this.props.success &&
      this.state.calledOn === this.props.user.id
    ) {
      const id = this.props.user.username;
      if (!this.props.toastify.toast.isActive(id)) {
        this.props.toastify.toast.success(
          <Toast
            id={id}
            success
            title={this.props.intl.formatMessage(messages.success)}
            content={this.props.intl.formatMessage(
              messages.conferma_reset_password_success,
              {
                user: this.props.user.fullname || this.props.user.username,
              },
            )}
          />,
        );
        this.setState({ calledOn: null });
      } else this.props.toastify.toast.dismiss(id);
    }
    if (
      prevProps.error !== this.props.error &&
      !prevProps.error &&
      this.props.error &&
      this.state.calledOn === this.props.user.id
    ) {
      const id = this.props.user.username;
      if (!this.props.toastify.toast.isActive(id)) {
        this.props.toastify.toast.success(
          <Toast
            error
            id={id}
            title={this.props.intl.formatMessage(messages.error)}
            content={this.props.intl.formatMessage(
              messages.conferma_reset_password_error,
              {
                user: this.props.user.fullname || this.props.user.username,
              },
            )}
          />,
        );
        this.setState({ calledOn: null });
      } else this.props.toastify.toast.dismiss(id);
    }
  }
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Table.Row key={this.props.user.username}>
        <Table.Cell className="fullname">
          {this.props.user.fullname
            ? this.props.user.fullname
            : this.props.user.username}
        </Table.Cell>
        {this.props.roles.map((role) => (
          <Table.Cell key={role.id}>
            {this.props.inheritedRole &&
            this.props.inheritedRole.includes(role.id) ? (
              <Icon
                name={ploneSVG}
                size="20px"
                color="#007EB1"
                title={'plone-svg'}
              />
            ) : (
              <Checkbox
                checked={this.props.user.roles.includes(role.id)}
                onChange={this.onChange}
                value={`${this.props.user.id}.${role.id}`}
              />
            )}
          </Table.Cell>
        ))}
        <Table.Cell textAlign="right">
          <Dropdown icon="ellipsis horizontal">
            <Dropdown.Menu className="left">
              <Dropdown.Item
                onClick={this.props.onDelete}
                value={this.props.user['@id']}
              >
                <Icon name={trashSVG} size="15px" />
                <FormattedMessage id="Delete" defaultMessage="Delete" />
              </Dropdown.Item>
              <Dropdown.Item
                onClick={this.onResetPassword}
                value={this.props.user['@id']}
              >
                <Icon name={replaceSVG} size="15px" />
                <FormattedMessage
                  id="Reset password"
                  defaultMessage="Reset password"
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default compose(
  injectIntl,
  injectLazyLibs(['toastify']),
  connect(
    (state) => ({
      loading: state.users.reset.loading,
      loaded: state.users.reset.loaded,
      error: state.users.reset.error,
      success:
        !state.users.reset.error &&
        !state.users.reset.loading &&
        state.users.reset.loaded,
    }),
    {
      resetPassword,
    },
  ),
)(RenderUsers);
