import {
  PageHeader,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  FolderContentsTable,
  Metadati,
} from '@package/components/views/Commons';
import { flattenToAppURL } from '@plone/volto/helpers';

const AllegatiReferenzeView = ({ content }) => {
  const addType = () => {
    if (content['@id'].includes('filefolder')) {
      return 'AlboFile';
    } else if (content['@id'].includes('persone')) {
      return 'Associato';
    } else {
      return 'Referenza';
    }
  };

  return (
    <div className="container px-4 my-4 mb-5">
      <SkipToMainContent />
      <PageHeader
        content={content}
        readingtime={null}
        showreadingtime={false}
        showdates={false}
        showtassonomiaargomenti={false}
        show_actions={false}
        addLink={`${flattenToAppURL(content['@id'])}/add?type=${addType()}`}
      />

      <FolderContentsTable items={content?.items} content={content} />
      <Metadati content={content} />
    </div>
  );
};

export default AllegatiReferenzeView;
