import { TextOrBlocks } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Button } from 'design-react-kit/dist/design-react-kit';
import { updateUser, getUser } from '@plone/volto/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TermsAndConditionsView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => {
    return state.users?.user;
  });

  const acceptTerms = async (userId) => {
    await dispatch(updateUser(userId, { agreed_terms: true }));
    await dispatch(getUser(userId));
    history.replace('/');
  };

  return (
    <div id="terms-and-conditions" className="ui container px-4">
      <h2 className="mb-4">{props.content.title}</h2>
      <div className="termsWrapper">
        <TextOrBlocks content={props.content} />
      </div>
      <div className="acceptTermsActions px-4 py-4">
        <Button onClick={async () => await acceptTerms(user?.id)}>
          Accetta i termini
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditionsView;
