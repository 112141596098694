import {
  CONFIRM_ANAGRAFICA,
  DUPLICATE_REFERENCE,
} from '../../constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function duplicateReference(path) {
  return {
    type: DUPLICATE_REFERENCE,
    request: {
      op: 'post',
      path: flattenToAppURL(`${path}/@duplicate-reference`),
    },
  };
}
export function confirmAnagrafica(path) {
  return {
    type: CONFIRM_ANAGRAFICA,
    request: {
      op: 'post',
      path: flattenToAppURL(path),
    },
  };
}
